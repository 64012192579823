import { Button } from "theme-ui";

import { Decimal } from "@liquity/lib-base";

import { useLiquity } from "../../hooks/LiquityContext";
import { useTransactionFunction } from "../Transaction";

type RedemptionActionProps = {
  transactionId: string;
  disabled?: boolean;
  type: string;
};

export const DistributionAction: React.FC<RedemptionActionProps> = ({
  transactionId,
  disabled,
  type
}) => {

  const {
    liquity: { send: liquity }
  } = useLiquity();

  const [sendTransaction] = useTransactionFunction(
    transactionId,
    liquity.claimDistribution.bind(liquity, type)
  );

  return (
    <Button disabled={disabled} onClick={sendTransaction}>
      Claim
    </Button>
  );
};