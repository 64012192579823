export type Lexicon = {
  term: string;
  description?: string;
  link?: string;
};

export const BORROW_FEE: Lexicon = {
  term: "Borrowing Fee",
  description:
    "The Borrowing Fee is a one-off fee charged as a percentage of the borrowed amount (in PXDC) and is part of a Vault's debt. The fee varies between 0.5% and 5% depending on PXDC redemption volumes.",
};

export const TVL: Lexicon = {
  term: "TVL",
  description:
    "The Total Value Locked (TVL) is the total value of PulseX Token locked as collateral in the system, given in PLSX and USD.",
};

export const STAKED_LQTY: Lexicon = {
  term: "Staked EARN",
  description:
    "The total amount of EARN that is staked for earning fee revenue.",
};

export const TCR: Lexicon = {
  term: "Total Collateral Ratio",
  description:
    "The ratio of the Dollar value of the entire system collateral at the current PLSX:USD price, to the entire system debt.",
};

export const RECOVERY_MODE: Lexicon = {
  term: "Recovery Mode",
  description:
    "Recovery Mode is activated when the Total Collateral Ratio (TCR) falls below 150%. When active, your Vault can be liquidated if its collateral ratio is below the TCR. The maximum collateral you can lose from liquidation is capped at 110% of your Vault's debt. Operations are also restricted that would negatively impact the TCR.",
};

export const STABILITY_POOL_LUSD: Lexicon = {
  term: "PXDC in Stability Pool",
  description:
    "The total PXDC currently held in the Stability Pool, expressed as an amount and a fraction of the PXDC supply.",
};

export const KICKBACK_RATE: Lexicon = {
  term: "Kickback Rate",
  description:
    "A rate between 0 and 100% set by the Frontend Operator that determines the fraction of EARN that will be paid out as a kickback to the Stability Providers using the frontend.",
};

export const ETH: Lexicon = {
  term: "PLSX",
};

export const LUSD: Lexicon = {
  term: "PXDC",
};

export const LQTY: Lexicon = {
  term: "EARN",
};

//
export const ALLOWANCE: Lexicon = {
  term: "PLSX Allowance",
  description: "PulseX(PLSX) Allowance",
};

//Shinto: Distribution
export const LIQUIDIDATION_NORMAL: Lexicon = {
  term: "Liquidation Price (Normal Mode)",
  description: "The USD value at which your Vault will drop below 110% Collateral Ratio and be at risk of liquidation. You should manage your position to de avoid liquidation by monitoring normal mode liquidation price",
};

export const LIQUIDIDATION_RECOVERY: Lexicon = {
  term: "Liquidation Price (Recovery Mode)",
  description: "The USD value at which your Vault will drop below 150% Collateral Ratio and be at risk of liquidation during Recovery Mode. You should be manage your position to avoid liquidation by monitoring the recovery mode liquidation price.",
};

export const DISTRIBUTION: Lexicon = {
  term: "Claim Your EARN Token",
  description: "Claim Your EARN Token",
};

export const TROVES: Lexicon = {
  term: "Vaults",
  description: "The total number of active Vaults in the system.",
};

export const LUSD_SUPPLY: Lexicon = {
  term: "PXDC supply",
  description: "The total PXDC minted by the Earn Protocol.",
};

export const MPPLSX: Lexicon = {
  term: "Market Price",
  description: "The current PLSX Market Price",
};

export const MPPXDC: Lexicon = {
  term: "Market Price",
  description: "The current PXDC Market Price",
};

export const MPEARN: Lexicon = {
  term: "Market Price",
  description: "The current EARN Market Price",
};

export const OPEARN: Lexicon = {
  term: "Oracle Price",
  description: "The current EARN Oracle Price",
};
