import React, { useEffect, useState } from "react";
import { Card, Heading, Box, Text, Image, Grid, Link } from "theme-ui";
import { AddressZero } from "@ethersproject/constants";
import { Decimal, Percent, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { useLiquity } from "../hooks/LiquityContext";
import { Statistic } from "./Statistic";
import * as l from "../lexicon";

// codeninja
import { TokenPrice } from "./TokenPrice";
import { Icon } from "./Icon";
import PLSXicon from "../assets/icons/pulsex.svg";
import EARNicon from "../assets/icons/EARNtoken.svg";
import PXDCicon from "../assets/icons/PXDCtoken.svg";
import { getTokensPrice } from "../utils/tokensPrice";
import { getEarnPrice } from "../utils/tokensPrice";
import { StaticRow } from "./Trove/Editor";

const selectBalances = ({
  accountBalance,
  lusdBalance,
  lqtyBalance,
  sacrificeDetails
}: LiquityStoreState) => ({
  accountBalance,
  lusdBalance,
  lqtyBalance,
  sacrificeDetails
});

const Balances: React.FC = () => {
  const { accountBalance, lusdBalance, lqtyBalance, sacrificeDetails } = useLiquitySelector(
    selectBalances
  );

  return (
    <Box sx={{ mb: 3 }}>
      <Heading>My Account Balances</Heading>
      <Statistic lexicon={l.ETH}>{accountBalance.shorten()}</Statistic>
      <Statistic lexicon={l.LUSD}>{lusdBalance.shorten()}</Statistic>
      <Statistic lexicon={l.LQTY}>{lqtyBalance.shorten()}</Statistic>
      {/* <Statistic lexicon={l.ALLOWANCE}>{plsxTokenAllowance.prettify()}</Statistic> */}
    </Box>
  );
};

// const GitHubCommit: React.FC<{ children?: string }> = ({ children }) =>
//   children?.match(/[0-9a-f]{40}/) ? (
//     <Link href={`https://github.com/liquity/dev/commit/${children}`}>{children.substr(0, 7)}</Link>
//   ) : (
//     <>unknown</>
//   );

type SystemStatsProps = {
  variant?: string;
  showBalances?: boolean;
  onClose?: () => void;
};

const select = ({
  numberOfTroves,
  trove,
  price,
  total,
  lusdInStabilityPool,
  borrowingRate,
  redemptionRate,
  totalStakedLQTY,
  frontend,
  plsxTokenAllowance, //Shinto: PlsxTokenAllowance
  sacrificeDetails
}: LiquityStoreState) => ({
  numberOfTroves,
  trove,
  price,
  total,
  lusdInStabilityPool,
  borrowingRate,
  redemptionRate,
  totalStakedLQTY,
  kickbackRate: frontend.status === "registered" ? frontend.kickbackRate : null,
  plsxTokenAllowance, //Shinto: PlsxTokenAllowance
  sacrificeDetails
});

export const SystemStats: React.FC<SystemStatsProps> = ({
  variant = "info",
  showBalances,
  onClose
}) => {
  const {
    liquity: {
      connection: {
        // codeninja
        addresses: {
          plxToken: plxToken,
          lqtyToken: earnToken,
          lusdToken: pxdcToken,
        },
        version: frontendTag,
      }, //contractsVersion removed
    },
  } = useLiquity();

  // codeninja
  const testnet = "https://scan.mypinata.cloud/ipfs/bafybeidn64pd2u525lmoipjl4nh3ooa2imd7huionjsdepdsphl5slfowy/#/";
  // const pulsexapp = "https://pulsex.mypinata.cloud/ipfs/bafybeidea3ibq4lu5t6vk6ihp4iuznjb3ltsdm5y2shv4atxgyd3d33aim/#/";
  const pulsexapp = "https://app.piteas.io/#/swap?inputCurrency=PLS&outputCurrency=";

  // add token to wallet
  const addTokenToWallet = (
    tokenSymbol: string,
    tokenAddress: string,
    tokenImage: string
  ) => async () => {
    if (window.ethereum) {
      try {
        const { ethereum } = window as any;
        let url = "";

        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
          url = `http://localhost:3000/`;
        } else {
          url = `https://dev.d3t4kuh2evv701.amplifyapp.com/`;
        }

        await ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: 18,
              image: url + tokenImage,
            },
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const {
    numberOfTroves,
    trove,
    price,
    lusdInStabilityPool,
    total,
    borrowingRate,
    totalStakedLQTY,
    kickbackRate,
    plsxTokenAllowance, //Shinto: PlsxTokenAllowance
    sacrificeDetails
  } = useLiquitySelector(select);
  
  const lusdInStabilityPoolPct =
    total.debt.nonZero && new Percent(lusdInStabilityPool.div(total.debt));
  const totalCollateralRatioPct = new Percent(total.collateralRatio(price));
  const borrowingFeePct = new Percent(borrowingRate);
  const kickbackRatePct =
    frontendTag === AddressZero ? "100" : kickbackRate?.mul(100).prettify();

  const [ tokensPrice, setTokensPrice ] = useState({
    "EARN": 0.0,
    "PXDC": 0.0
  });
  
  useEffect( () => {
    // const tokensPrices = async () => {
    //   const response = await getTokensPrice();
    //   setTokensPrice(response);
    //   localStorage.setItem('tokensPrice', JSON.stringify(response));
    // };

    const earnPrices = async () => {
      const response = await getEarnPrice();
      setTokensPrice(response);
      localStorage.setItem('tokensPrice', JSON.stringify(response));
    };
    
    // tokensPrices();
    earnPrices();
  },[]);

  return (
    <Card {...{ variant }}>
      {showBalances && <Balances />}

      <Heading>EARN Statistics</Heading>

      {/* codeninja */}
      <Grid sx={{ mt: 30 }} gap={4} columns={[3]}>
        <Box sx={{ textAlign: "center" }}>
          <Image
            sx={{ width: 40, height: 40, cursor: "pointer" }}
            src={PLSXicon}
            variant="avatar"
            onClick={addTokenToWallet("PLSX", plxToken, PLSXicon)}
          />
          <Heading as="h3" sx={{ mt: 0, mb: 0, fontWeight: "body" }}>
            PLSX
          </Heading>
          <Text sx={{ fontSize: 1, color: "#00ac3a" }}>${ price.prettify(8) }</Text>
          <Text sx={{ fontSize: 1, display: "block", mb: 15, fontWeight: 200 }}>
            <TokenPrice lexicon={l.OPEARN} />
            <Link href="#" sx={{ mr: 1 }}>
              <Icon name="info-circle" />
            </Link>
            <Link href="#" sx={{ mr: 1 }}>
              <Icon name="satellite" />
            </Link>
          </Text>
        </Box>

        {/* <Box sx={{ textAlign: "center" }}>
          <Image
            sx={{ width: 30, height: 30, cursor: "pointer" }}
            src={PLSXicon}
            variant="avatar"
            onClick={addTokenToWallet("PLSX", plxToken, PLSXicon)}
          />
          <Heading as="h3" sx={{ mt: 10, mb: 0, fontWeight: "body" }}>
            PLSX
          </Heading>
          <Text sx={{ fontSize: 1, color: "#00ac3a" }}>${ tokensPrice.plsx.toFixed(6) }</Text>
          <Text sx={{ fontSize: 1, display: "block", mb: 15, fontWeight: 200 }}>
            <TokenPrice lexicon={l.MPPLSX} />
            <Link
              target="_blank"
              href="https://www.coingecko.com/en/coins/pulsex"
              sx={{ mr: 1 }}
            >
              <Icon name="info-circle" />
            </Link>
            <Link
              target="_blank"
              href={testnet + "address/" + plxToken}
              sx={{ mr: 1 }}
            >
              <Icon name="chart-bar" />
            </Link>
            <Link
              target="_blank"
              href={pulsexapp + "swap?outputCurrency=" + plxToken}
            >
              <Icon name="exchange-alt" />
            </Link>
          </Text>
        </Box> */}

        <Box sx={{ textAlign: "center" }}>
          <Image
            sx={{ width: 40, height: 40, cursor: "pointer" }}
            src={EARNicon}
            variant="avatar"
            onClick={addTokenToWallet("EARN", earnToken, EARNicon)}
          />
          <Heading as="h3" sx={{ mt: 0, mb: 0, fontWeight: "body" }}>
            EARN
          </Heading>
          <Text sx={{ fontSize: 1, color: "#00ac3a" }}>${ tokensPrice.EARN }</Text>
          <Text sx={{ fontSize: 1, display: "block", mb: 15, fontWeight: 200 }}>
            <TokenPrice lexicon={l.MPEARN} />
            <Link href="#" sx={{ mr: 1 }}>
              <Icon name="info-circle" />
            </Link>
            <Link
              target="_blank"
              href={testnet + "address/" + earnToken}
              sx={{ mr: 1 }}
            >
              <Icon name="chart-bar" />
            </Link>
            <Link
              target="_blank"
              href={pulsexapp + earnToken}
            >
              <Icon name="exchange-alt" />
            </Link>
          </Text>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Image
            sx={{ width: 40, height: 40, cursor: "pointer" }}
            src={PXDCicon}
            variant="avatar"
            onClick={addTokenToWallet("PXDC", pxdcToken, PXDCicon)}
          />
          <Heading as="h3" sx={{ mt: 0, mb: 0, fontWeight: "body" }}>
            PXDC
          </Heading>
          <Text sx={{ fontSize: 1, color: "#00ac3a" }}>${ tokensPrice.PXDC }</Text>
          <Text sx={{ fontSize: 1, display: "block", mb: 15, fontWeight: 200 }}>
            <TokenPrice lexicon={l.MPPXDC} />
            <Link href="#" sx={{ mr: 1 }}>
              <Icon name="info-circle" />
            </Link>
            <Link
              target="_blank"
              href={testnet + "address/" + pxdcToken}
              sx={{ mr: 1 }}
            >
              <Icon name="chart-bar" />
            </Link>
            <Link
              target="_blank"
              href={pulsexapp + pxdcToken}
            >
              <Icon name="exchange-alt" />
            </Link>
          </Text>
        </Box>
      </Grid>

      <Heading as="h2" sx={{ mt: 3, fontWeight: "body" }}>
        Protocol
      </Heading>

      <Statistic lexicon={l.BORROW_FEE}>
        {borrowingFeePct.toString(2)}
      </Statistic>

      <Statistic lexicon={l.TVL}>
        {total.collateral.shorten()}{" "}
        <Text sx={{ fontSize: 1 }}>&nbsp;PLSX</Text>
        <Text sx={{ fontSize: 1 }}>
          &nbsp;(${Decimal.from(total.collateral.mul(price)).shorten()})
        </Text>
      </Statistic>
      <Statistic lexicon={l.TROVES}>
        {Decimal.from(numberOfTroves).prettify(0)}
      </Statistic>
      <Statistic lexicon={l.LUSD_SUPPLY}>{total.debt.shorten()}</Statistic>
      {lusdInStabilityPoolPct && (
        <Statistic lexicon={l.STABILITY_POOL_LUSD}>
          {lusdInStabilityPool.shorten()}
          <Text sx={{ fontSize: 1 }}>
            &nbsp;({lusdInStabilityPoolPct.toString(1)})
          </Text>
        </Statistic>
      )}
      <Statistic lexicon={l.STAKED_LQTY}>{totalStakedLQTY.shorten()}</Statistic>
      <Statistic lexicon={l.TCR}>
        {totalCollateralRatioPct.prettify()}
      </Statistic>
      <Statistic lexicon={l.RECOVERY_MODE}>
        {total.collateralRatioIsBelowCritical(price) ? (
          <Box color="danger">Yes</Box>
        ) : (
          "No"
        )}
      </Statistic>
      {/* Shinto: PlsxTokenAllowance */}
      <Statistic lexicon={l.ALLOWANCE}>
        {plsxTokenAllowance.gt(1e15)
          ? "Infinite"
          : plsxTokenAllowance.shorten()}
      </Statistic>

      <Statistic lexicon={l.LIQUIDIDATION_NORMAL}>
        {!trove.collateral.isZero 
          ? trove.debt
            .mul(Decimal.from(110))
            .div(trove.collateral.mul(Decimal.from(100)))
            .toString(7)
          : "0.00"
        } USD
      </Statistic>

      <Statistic lexicon={l.LIQUIDIDATION_RECOVERY}>
        {!trove.collateral.isZero 
          ? trove.debt
            .mul(Decimal.from(150))
            .div(trove.collateral.mul(Decimal.from(100)))
            .toString(7)
          : "0.00"
        } USD
      </Statistic>

      {/* Shinto: DISTRIBUTION */}
      {
        (sacrificeDetails[0][2] || sacrificeDetails[1][2]) ? 
        <Statistic lexicon={l.DISTRIBUTION}>
          <Link href="/#/distribution" sx={{ mr: 1 }} onClick={onClose} >
            Claim Reward
          </Link>
        </Statistic>
        : <></>
      }

      <Heading as="h2" sx={{ mt: 3, fontWeight: "body" }}>
        {/* Frontend */}
      </Heading>
      {kickbackRatePct && (
        <Statistic lexicon={l.KICKBACK_RATE}>{kickbackRatePct}%</Statistic>
      )}

      <Box sx={{ mt: 3, opacity: 0.66 }}>
        {/* <Box sx={{ fontSize: 0 }}>
          Contracts version: <GitHubCommit>{contractsVersion}</GitHubCommit>
        </Box> */}
        {/* <Box sx={{ fontSize: 0 }}>Deployed: {deploymentDate.toLocaleString()}</Box>
        <Box sx={{ fontSize: 0 }}>
          Frontend version:{" "}
          {process.env.NODE_ENV === "development" ? (
            "development"
          ) : (
            <GitHubCommit>{process.env.REACT_APP_VERSION}</GitHubCommit>
          )}
        </Box> */}
      </Box>
    </Card>
  );
};
