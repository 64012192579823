import { BlockPolledLiquityStoreState } from "@liquity/lib-ethers";
import { useLiquitySelector } from "@liquity/lib-react";

import { useLiquity } from "../../hooks/LiquityContext";
import { DisabledDistribution } from "./DisabledDistribution";
import { DistributionManager } from "./DistributionManager";

const SECONDS_IN_ONE_DAY = 24 * 60 * 60;

const selectBlockTimestamp = ({ blockTimestamp }: BlockPolledLiquityStoreState) => blockTimestamp;

export const Distribution: React.FC = () => {
  const {
    liquity: {
      connection: { deploymentDate, bootstrapPeriod }
    }
  } = useLiquity();

  const blockTimestamp = useLiquitySelector(selectBlockTimestamp);

  const bootstrapPeriodDays = Math.round(bootstrapPeriod / SECONDS_IN_ONE_DAY);
  const deploymentTime = deploymentDate.getTime() / 1000;
  const bootstrapEndTime = deploymentTime + bootstrapPeriod;
  const bootstrapEndDate = new Date(bootstrapEndTime * 1000);
  const redemptionDisabled = blockTimestamp < bootstrapEndTime;

  // if (redemptionDisabled) {
  //   return <DisabledDistribution disabledDays={bootstrapPeriodDays} unlockDate={bootstrapEndDate} />;
  // }

  return <DistributionManager />;
};