import React, { useCallback } from "react";
import { Text, Flex, Box, Heading, Image } from "theme-ui";

import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { COIN, GT } from "../strings";
import { useLiquity } from "../hooks/LiquityContext";
import { shortenAddress } from "../utils/shortenAddress";

import { Icon } from "./Icon";
import { useBondView } from "./Bonds/context/BondViewContext";
import { useBondAddresses } from "./Bonds/context/BondAddressesContext";
import { useWeb3React } from "@web3-react/core";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

//Shinto: plsxTokenAllowance
const select = ({ accountBalance, lusdBalance, lqtyBalance }: LiquityStoreState) => ({
  accountBalance,
  lusdBalance,
  lqtyBalance
});

export const UserAccount: React.FC = () => {
  const { deactivate } = useWeb3React<unknown>();

  const { account, liquity } = useLiquity();
  const contractAddresses = liquity.connection.addresses;
  
  const { accountBalance, lusdBalance: realLusdBalance, lqtyBalance } = useLiquitySelector(select);
  
  const { lusdBalance: customLusdBalance } = useBondView();
  const { LUSD_OVERRIDE_ADDRESS } = useBondAddresses();

  const lusdBalance = LUSD_OVERRIDE_ADDRESS === null ? realLusdBalance : customLusdBalance;
  const copyClipboard = (currency: any, address: any) => {

    navigator.clipboard.writeText(address);
    toast.success(`${currency} Address Copied!`, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  return (
    <Box sx={{ display: ["none", "flex"] }}>
      <Flex sx={{ alignItems: "center" }}>
        <Icon name="user-circle" size="lg" />
        <Flex sx={{ ml: 3, mr: 4, flexDirection: "column" }}>
          <Heading sx={{ fontSize: 1 }}>Connected as</Heading>
          <Text as="span" sx={{ fontSize: 1 }}>
            {shortenAddress(account)}&nbsp;
            <span><Image src="./icons/disconnect.svg" sx={{ height:"16px" }} onClick={ deactivate }/></span>
          </Text>
        </Flex>
      </Flex>

      <Flex sx={{ alignItems: "center" }}>
        <Icon name="wallet" size="lg" />

        {([
          ["PLSX", accountBalance, contractAddresses.plxToken],
          [COIN, Decimal.from(lusdBalance || 0), contractAddresses.lusdToken],
          [GT, Decimal.from(lqtyBalance), contractAddresses.lqtyToken],
          // ["bLUSD", Decimal.from(bLusdBalance || 0)]
        ] as const).map(([currency, balance, address], i) => (
          <Flex key={i} sx={{ ml: 3, flexDirection: "column", alignItems: "center" }}>
            <Heading sx={{ fontSize: 1 }} onClick={() => copyClipboard(currency, address) }>{currency} <Icon name="clipboard" size="sm" /></Heading>
            
            <Text sx={{ fontSize: 1 }}>{balance.shorten()}</Text>
          </Flex>
        ))}
      </Flex>

      <ToastContainer />

    </Box>
  );
};
