import React from "react";
import { Container, Flex, Box, Grid, Link } from "theme-ui";
import { useHistory } from "react-router-dom";
import { Icon } from "./Icon";
import Logo from "../assets/images/logo-pc.png";

import Twitter from "../assets/icons/twitter.svg";
import Telegram from "../assets/icons/telegram.svg";
import Youtube from "../assets/icons/youtube.svg";
import Discord from "../assets/icons/discord.svg";
import Medium from "../assets/icons/medium.svg";
import Docs from "../assets/icons/docs2.svg";

import "../assets/css/footer.css";

export const Footer = () => {
  const history = useHistory();

  const dashboard = () => {
    history.push("/");
  };

  const redemption = () => {
    history.push("/redemption");
  };
  const riskyvaults = () => {
    history.push("/risky-vaults");
  };

  return (
    <Container
      sx={{
        backgroundColor: "#000",
        p: 0,
        //position: "fixed",
        //bottom: 0,
      }}
      variant="footer"
      className="mainfooter"
    >
      <Container sx={{ maxWidth: 1260, paddingLeft: 20, paddingRight: 20 }}>
        <Grid gap={0} columns={[3]}>
          <Box sx={{ textAlign: "left" }}>
            <h3>Quick Links</h3>
            <ul>
              <li>
                <a target="_blank" href="https://core.powercity.io/">
                  Powercity CORE
                </a>
              </li>
              <li>
                <a href="/">Amplifier</a>
              </li>
              <li>
                <a href="/">PixelPark Marketplace</a>
              </li>
            </ul>
          </Box>
          <Box sx={{ textAlign: "left" }}>
            <h3>EARN PROTOCOL</h3>
            <ul>
              <li onClick={dashboard}>Dashboard</li>
              <li onClick={redemption}>Redemption</li>
              <li onClick={riskyvaults}>Risky Vaults</li>
            </ul>
          </Box>
          <Box sx={{ textAlign: "left" }}>
            <a target="_blank" href="https://powercity.io/">
              <img alt="" className="logo" src={Logo} />
            </a>

            <div className="social-row">
              <Link href="https://t.me/POWERCITYio" target="_blank">
                <img alt="" className="logo" src={Telegram} />
              </Link>

              <Link href="https://discord.gg/w574jPnQpZ" target="_blank">
                <img alt="" className="logo" src={Discord} />
              </Link>

              <Link href="https://twitter.com/POWERCITYio" target="_blank">
                <img alt="" className="logo" src={Twitter} />
              </Link>

              <Link href="https://medium.com/@powercity" target="_blank">
                <img alt="" className="logo" src={Medium} />
              </Link>
              <Link
                href="https://www.youtube.com/channel/UCKoFHpAen2bjhqp4Vzq6ZRQ"
                target="_blank"
              >
                <img alt="" className="logo" src={Youtube} />
              </Link>
              <Link href="https://docs.powercity.io/earn-protocol" target="_blank">
                <img alt="" className="logo" src={Docs} />
              </Link>
            </div>
          </Box>
        </Grid>
      </Container>
    </Container>
  );
};
