type InactiveView = "INACTIVE";
type StakingView = "STAKING";
type ActiveView = "ACTIVE";
type AdjustingView = "ADJUSTING";
type DisabledView = "DISABLED";

export type FarmView = InactiveView | StakingView | ActiveView | AdjustingView | DisabledView;

type StakePressedEvent = "STAKE_PRESSED2";
type AdjustPressedEvent = "ADJUST_PRESSED2";
type CancelPressedEvent = "CANCEL_PRESSED2";
type StakeApprovedEvent = "STAKE_APPROVED2";
type StakeConfirmedEvent = "STAKE_CONFIRMED2";
type ClaimRewardConfirmedEvent = "CLAIM_REWARD_CONFIRMED2";
type UnstakeConfirmedEvent = "UNSTAKE_CONFIRMED2";
type UnstakeAndClaimConfirmedEvent = "UNSTAKE_AND_CLAIM_CONFIRMED2";

export type FarmEvent =
  | StakePressedEvent
  | AdjustPressedEvent
  | CancelPressedEvent
  | StakeApprovedEvent
  | StakeConfirmedEvent
  | ClaimRewardConfirmedEvent
  | UnstakeConfirmedEvent
  | UnstakeAndClaimConfirmedEvent;

type FarmEventTransitions = Record<FarmView, Partial<Record<FarmEvent, FarmView>>>;

export const transitions: FarmEventTransitions = {
  INACTIVE: {
    STAKE_PRESSED2: "STAKING"
  },
  STAKING: {
    CANCEL_PRESSED2: "INACTIVE",
    STAKE_CONFIRMED2: "ACTIVE",
    STAKE_APPROVED2: "STAKING"
  },
  ACTIVE: {
    ADJUST_PRESSED2: "ADJUSTING",
    CLAIM_REWARD_CONFIRMED2: "ACTIVE",
    UNSTAKE_AND_CLAIM_CONFIRMED2: "INACTIVE"
  },
  ADJUSTING: {
    CANCEL_PRESSED2: "ACTIVE",
    STAKE_CONFIRMED2: "ACTIVE",
    STAKE_APPROVED2: "ADJUSTING",
    UNSTAKE_CONFIRMED2: "ACTIVE"
  },
  DISABLED: {
    CLAIM_REWARD_CONFIRMED2: "DISABLED",
    UNSTAKE_AND_CLAIM_CONFIRMED2: "DISABLED"
  }
};