import axios from "axios";

export const getTokensPrice = async () => {
  try {
    const response = await axios.get(
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=pulsex,tether-pulsechain,hex"
    );

    const tokens = response.data.reduce((result: any, token: any) => {
      result[token.symbol] = token.current_price;
      return result;
    }, {});

    return tokens;
  } catch (error) {
    throw new Error("Failed to retrieve price from Coingecko");
  }
};

export const getEarnPrice = async () => {
  try {
    const response = await axios.get(
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xed77CbbB80e5a5C3A1FE664419d6F690766b5913,0xabb36512813194b12a82a319783dbb455652440a,0x1b45b9148791d3a104184cd5dfe5ce57193a3ee9"
    );

    const tokens = response.data.pairs.reduce((result: any, token: any) => {
      result[token.baseToken.symbol] = token.priceUsd;
      return result;
    }, {});

    return tokens;
  } catch (error) {
    throw new Error("Failed to retrieve price from Dex Screeener");
  }
};
