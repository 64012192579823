import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { Card, Heading, Box, Text, Image, Grid, Link, Spinner } from "theme-ui";
import { Statistic } from "./Statistic";
import * as l from "../lexicon";
import { TokenPrice } from "./TokenPrice";
import { Icon } from "./Icon";

import PLSXicon from "../assets/icons/pulsex.svg";
import EARNicon from "../assets/icons/EARNtoken.svg";
import PXDCicon from "../assets/icons/PXDCtoken.svg";
import abi from "./Disconnected/abi";

import { getTokensPrice } from "../utils/tokensPrice";
import { getEarnPrice } from "../utils/tokensPrice";

type SystemStatsDisconnectedProps = {
  variant?: string;
};

export const SystemStatsDisconnected: React.FC<SystemStatsDisconnectedProps> = ({}) => {
  const provider = new ethers.providers.JsonRpcProvider(
    "https://rpc-pulsechain.g4mm4.io" // #### NOTE! ####, change to mainnet url when launch.
  );

  // #### NOTE! ####, change to mainnet url when launch.
  const mainnet =
    "https://scan.mypinata.cloud/ipfs/bafybeidn64pd2u525lmoipjl4nh3ooa2imd7huionjsdepdsphl5slfowy/#/";
  const pulsexapp =
    "https://app.piteas.io/#/swap?inputCurrency=PLS&outputCurrency=";

  // add token to wallet
  const addTokenToWallet = (
    tokenSymbol: string,
    tokenAddress: string,
    tokenImage: string
  ) => async () => {
    if (window.ethereum) {
      try {
        const { ethereum } = window as any;
        let url = "";

        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
          url = `http://localhost:3000/`;
        } else {
          url = `https://earn.powercity.io/`; // #### NOTE! ####, change to mainnet url when launch.
        }

        await ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: 18,
              image: url + tokenImage,
            },
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  // #### NOTE! ####, change to mainnet url when launch.
  const plsxaddr = "0x95B303987A60C71504D99Aa1b13B4DA07b0790ab";
  const pxdcToken = "0xeB6b7932Da20c6D7B3a899D5887d86dfB09A6408";
  const earnaddr = "0xb513038BbFdF9D40B676F41606f4F61D4b02c4A2";

  const earnStaking = "0xd92DF13b6cd9eA8fc116b1865D2d72Be41d74B1a";
  const troveManager = "0x118b7CF595F6476a18538EAF4Fbecbf594338B39";
  const activepools = "0xB1e03a2dC49eFBA2Af78a84D530987f99E72831f";
  const defaultpool = "0x4D867a74525BebF1D3C747324FC6e802401B225A";
  const stabilitypool = "0x02E842db8d6C78D17cF8146009Fb864094d95319";

  const plsxContract = new ethers.Contract(plsxaddr, abi.combined, provider);
  const earnStakingContract = new ethers.Contract(
    earnStaking,
    abi.combined,
    provider
  );
  const troveManagerContract = new ethers.Contract(
    troveManager,
    abi.combined,
    provider
  );
  const pxdcTokenContract = new ethers.Contract(
    pxdcToken,
    abi.combined,
    provider
  );

  const [loading, setLoading] = useState(true);
  const [earnStakeAmount, setEarnStakeAmount] = useState<any>("0");
  const [vaults, setVaults] = useState<any>("0");
  const [borrowRate, setBorrowRate] = useState<any>("0");
  const [tvl, setTvl] = useState<any>("0");
  const [tvlUsd, setTvlUsd] = useState<any>("0");
  const [mintedPxdc, setMintedPxdc] = useState<any>("0");
  const [poolPxdc, setPoolPxdc] = useState<any>("0");
  const [pxdcInStabilityPoolPct, setpxdcInStabilityPoolPct] = useState<number>(
    0
  );
  const [totalCollPct, setTotalCollPct] = useState<number>(0);

  // shorten function
  const shorten = (number: any, decimalPlaces: number) => {
    const roundedNumber = Math.ceil(number * 10) / 10; // Round up to one decimal place
    const suffixes = ["", "K", "M", "B", "T"];
    const magnitude = Math.floor(Math.log10(number) / 3);
    const formattedNumber = (
      roundedNumber / Math.pow(10, magnitude * 3)
    ).toFixed(decimalPlaces); // Fixed to one decimal place
    return formattedNumber + suffixes[magnitude];
  };

  // #### NOTE! ####, change to EARN ORACLE when launch.
  const [tokensPrice, setTokensPrice] = useState({
    EARN: 0.0,
    PXDC: 0.0,
    PLSX: 0.0,
  });

  useEffect(() => {
    async function fetchData() {
      const response = await getEarnPrice();
      console.log(response);

      setTokensPrice(response);
      localStorage.setItem("tokensPrice", JSON.stringify(response));

      // Continue with the rest of the logic using tokenPrice.PLSX
      // Ensure tokenPrice.PLSX is defined before proceeding
      if (response.PLSX) {
        // tvl (active & default)
        const totalValueLockedActive = await plsxContract.balanceOf(
          activepools
        );
        const totalValueLockedDefault = await plsxContract.balanceOf(
          defaultpool
        );
        const tvlactive = ethers.utils.formatUnits(
          totalValueLockedActive.toString(),
          18
        );
        const tvldefault = ethers.utils.formatUnits(
          totalValueLockedDefault.toString(),
          18
        );
        const totalValueLocked = Number(tvlactive) + Number(tvldefault);

        setTvl(shorten(totalValueLocked, 1));

        // tvl usd
        setTvlUsd(shorten(Number(totalValueLocked) * Number(response.PLSX), 1));

        // total staked
        const totalEARNStaked = await earnStakingContract.totalLQTYStaked();
        setEarnStakeAmount(
          shorten(ethers.utils.formatUnits(totalEARNStaked.toString(), 18), 1)
        );

        // total minted
        const totalPXDCissued = await pxdcTokenContract.totalSupply();
        setMintedPxdc(
          shorten(ethers.utils.formatUnits(totalPXDCissued.toString(), 18), 2)
        );

        // total in pool
        const totalPXDCinpool = await pxdcTokenContract.balanceOf(
          stabilitypool
        );
        setPoolPxdc(
          shorten(ethers.utils.formatUnits(totalPXDCinpool.toString(), 18), 2)
        );

        // total debt
        const totalSystemdebt = await troveManagerContract.getEntireSystemDebt();

        // total system collateral
        const totalCollateral = await troveManagerContract.getEntireSystemColl();

        // PXDC In StabilityPool %
        setpxdcInStabilityPoolPct((totalPXDCinpool / totalSystemdebt) * 100);

        // total system collateral %
        setTotalCollPct(
          ((totalCollateral * Number(response.PLSX || 0)) / totalSystemdebt) *
            100
        );

        // tvl
        setTvl(shorten(totalValueLocked, 1));

        //total vaults
        const totalVaults = await troveManagerContract.getTroveOwnersCount();
        setVaults(totalVaults.toString());

        //get Borrowing Rate
        const borrowingRate = await troveManagerContract.getBorrowingRate();
        const percentage = (borrowingRate / 1e16).toFixed(2) + "%";
        setBorrowRate(percentage);

        setLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    <>
      <Heading>EARN Statistics</Heading>

      {loading ? (
        <>
          <p>Loading...</p> <Spinner size="28px" sx={{ color: "text" }} />
        </>
      ) : (
        <>
          <Grid sx={{ mt: 30 }} gap={4} columns={[3]}>
            <Box sx={{ textAlign: "center" }}>
              <Image
                sx={{ width: 40, height: 40, cursor: "pointer" }}
                src={PLSXicon}
                variant="avatar"
                onClick={addTokenToWallet("PLSX", plsxaddr, PLSXicon)}
              />
              <Heading as="h3" sx={{ mt: 0, mb: 0, fontWeight: "body" }}>
                PLSX
              </Heading>
              <Text sx={{ fontSize: 1, color: "#00ac3a" }}>
                ${tokensPrice.PLSX}
              </Text>
              <Text
                sx={{ fontSize: 1, display: "block", mb: 15, fontWeight: 200 }}
              >
                <TokenPrice lexicon={l.OPEARN} />
                <Link href="#" sx={{ mr: 1 }}>
                  <Icon name="info-circle" />
                </Link>
                <Link href="#" sx={{ mr: 1 }}>
                  <Icon name="satellite" />
                </Link>
              </Text>
            </Box>

            <Box sx={{ textAlign: "center" }}>
              <Image
                sx={{ width: 40, height: 40, cursor: "pointer" }}
                src={EARNicon}
                variant="avatar"
                onClick={addTokenToWallet("EARN", earnaddr, EARNicon)}
              />
              <Heading as="h3" sx={{ mt: 0, mb: 0, fontWeight: "body" }}>
                EARN
              </Heading>
              <Text sx={{ fontSize: 1, color: "#00ac3a" }}>
                ${tokensPrice.EARN}
              </Text>
              <Text
                sx={{ fontSize: 1, display: "block", mb: 15, fontWeight: 200 }}
              >
                <TokenPrice lexicon={l.MPEARN} />
                <Link href="#" sx={{ mr: 1 }}>
                  <Icon name="info-circle" />
                </Link>
                <Link
                  target="_blank"
                  href={mainnet + "address/" + earnaddr}
                  sx={{ mr: 1 }}
                >
                  <Icon name="chart-bar" />
                </Link>
                <Link target="_blank" href={pulsexapp + earnaddr}>
                  <Icon name="exchange-alt" />
                </Link>
              </Text>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Image
                sx={{ width: 40, height: 40, cursor: "pointer" }}
                src={PXDCicon}
                variant="avatar"
                onClick={addTokenToWallet("PXDC", pxdcToken, PXDCicon)}
              />
              <Heading as="h3" sx={{ mt: 0, mb: 0, fontWeight: "body" }}>
                PXDC
              </Heading>
              <Text sx={{ fontSize: 1, color: "#00ac3a" }}>
                ${tokensPrice.PXDC}
              </Text>
              <Text
                sx={{ fontSize: 1, display: "block", mb: 15, fontWeight: 200 }}
              >
                <TokenPrice lexicon={l.MPPXDC} />
                <Link href="#" sx={{ mr: 1 }}>
                  <Icon name="info-circle" />
                </Link>
                <Link
                  target="_blank"
                  href={mainnet + "address/" + pxdcToken}
                  sx={{ mr: 1 }}
                >
                  <Icon name="chart-bar" />
                </Link>
                <Link target="_blank" href={pulsexapp + pxdcToken}>
                  <Icon name="exchange-alt" />
                </Link>
              </Text>
            </Box>
          </Grid>

          <Heading as="h2" sx={{ mt: 3, fontWeight: "body" }}>
            Protocol
          </Heading>

          <Statistic lexicon={l.BORROW_FEE}>{borrowRate}</Statistic>

          <Statistic lexicon={l.TVL}>
            {tvl} <Text sx={{ fontSize: 1 }}>&nbsp;PLSX</Text>
            <Text sx={{ fontSize: 1 }}>&nbsp;(${tvlUsd})</Text>
          </Statistic>
          <Statistic lexicon={l.TROVES}>{vaults}</Statistic>
          <Statistic lexicon={l.LUSD_SUPPLY}>{mintedPxdc}</Statistic>

          <Statistic lexicon={l.STABILITY_POOL_LUSD}>
            {poolPxdc == "NaNundefined" ? "0" : poolPxdc}
            <Text sx={{ fontSize: 1 }}>
              &nbsp;({pxdcInStabilityPoolPct.toFixed(1)}%)
            </Text>
          </Statistic>

          <Statistic lexicon={l.STAKED_LQTY}>
            {earnStakeAmount == "NaNundefined" ? "0" : earnStakeAmount}
          </Statistic>
          <Statistic lexicon={l.TCR}>{totalCollPct.toFixed(1)}%</Statistic>
        </>
      )}
    </>
  );
};
