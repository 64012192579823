import React from "react";
import { Box } from "theme-ui";
import { InfoIcon } from "./InfoIcon";
import type { Lexicon } from "../lexicon";

type TokenPriceProps = {
  lexicon: Lexicon;
};

export const TokenPrice: React.FC<TokenPriceProps> = ({
  lexicon,
  children,
}) => {
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "rgba(0, 0, 0, 0.1)" }}>
        {lexicon.term}
        {lexicon.term && (
          <InfoIcon
            size="xs"
            tooltip={lexicon.description}
            link={lexicon.link}
          />
        )}
      </Box>
    </>
  );
};
