import { Decimal } from "@liquity/lib-base";
import { ethers } from "ethers";
import { useEffect } from "react";

type UniswapResponse = {
  data?: {
    bundle: {
      ethPrice: string;
    } | null;
    token: {
      derivedETH: string;
    } | null;
    pair: {
      reserveUSD: string;
      totalSupply: string;
    } | null;
  };
  errors?: Array<{ message: string }>;
};

const uniswapQuery = (lqtyTokenAddress: string, uniTokenAddress: string) => `{
  token(id: "${lqtyTokenAddress.toLowerCase()}") {
    derivedETH
  },
  bundle(id: 1) {
    ethPrice
  },
  pair(id: "${uniTokenAddress.toLowerCase()}") {
    totalSupply
    reserveUSD
  }
}`;

// async function getLPPairPrice(token1Address, token2Address) {
//   try {
//       // Get reserves of token1 and token2 from DEX contract
//       const reserve1 = await dexContract.methods.getReserve(token1Address).call();
//       const reserve2 = await dexContract.methods.getReserve(token2Address).call();

//       // Calculate LP pair price (assuming token1/token2 pair)
//       const price = reserve1 / reserve2;
//       return price;
//   } catch (error) {
//       console.error("Error getting LP pair price:", error);
//       return null;
//   }
// }

// // Example usage
// const token1Address = '0x789...'; // Replace with actual token address
// const token2Address = '0xabc...'; // Replace with actual token address
// getLPPairPrice(token1Address, token2Address)
//   .then(price => {
//       console.log("LP pair price:", price);
//   });

export async function reservesAndSupply(contractAddress: any) {
  let reservesData:any=[], totalSupply = "";
  // useEffect(() => {
    // Replace with your contract address and ABI
    // const contractAddress = "0x..."; // Replace with your contract address
    const contractABI = [
      // ABI for your contract methods to retrieve reserves and total supply
      {
        "constant": true,
        "inputs": [],
        "name": "getReserves",
        "outputs": [
            {
                "internalType": "uint112",
                "name": "_reserve0",
                "type": "uint112"
            },
            {
                "internalType": "uint112",
                "name": "_reserve1",
                "type": "uint112"
            },
            {
                "internalType": "uint32",
                "name": "_blockTimestampLast",
                "type": "uint32"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
      {
        constant: true,
        inputs: [],
        name: "totalSupply",
        outputs: [{ name: "", type: "uint256" }],
        type: "function",
      },
    ];

    // Initialize ethers.js with the user's Ethereum provider (e.g., MetaMask)
    async function fetchData() {
      try {
        // Check if Ethereum provider is available
        if (window.ethereum) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();

          // Create a contract instance
          const contract = new ethers.Contract(
            contractAddress,
            contractABI,
            signer
          );

          reservesData = await contract.getReserves();
          console.log("🚀 ~ fetchData ~ reservesData:", reservesData)

          // Call the totalSupply function to get total supply
          const result = await contract.totalSupply();
          totalSupply = result.toString();
        } else {
          console.log(
            "Please install MetaMask or another Ethereum wallet extension."
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    await fetchData();
  // }, []);

  return { reservesData, totalSupply };
};

export async function fetchPrices(lqtyTokenAddress: string, uniTokenAddress: string) {
  const response = await window.fetch("https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      query: uniswapQuery(lqtyTokenAddress, uniTokenAddress),
      variables: null
    })
  });

  if (!response.ok) {
    return Promise.reject("Network error connecting to Uniswap subgraph");
  }

  const { data, errors }: UniswapResponse = await response.json();

  if (errors) {
    return Promise.reject(errors);
  }

  if (
    typeof data?.token?.derivedETH === "string" &&
    typeof data?.pair?.reserveUSD === "string" &&
    typeof data?.pair?.totalSupply === "string" &&
    typeof data?.bundle?.ethPrice === "string"
  ) {
    const ethPriceUSD = Decimal.from(data.bundle.ethPrice);
    const lqtyPriceUSD = Decimal.from(data.token.derivedETH).mul(ethPriceUSD);
    const uniLpPriceUSD = Decimal.from(data.pair.reserveUSD).div(
      Decimal.from(data.pair.totalSupply)
    );

    return { lqtyPriceUSD, uniLpPriceUSD };
  }

  return Promise.reject("Uniswap doesn't have the required data to calculate yield");
}