import React, { useEffect } from "react";
import { Button } from "theme-ui";
import { useLiquity } from "../../../hooks/LiquityContext";
import { Transaction, useMyTransactionState } from "../../Transaction";
import { useFarmView } from "../context/FarmViewContext";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

const transactionId = "farm-unstake-and-claim";
const selector = ({ liquidityMiningStake, liquidityMiningLQTYReward }: LiquityStoreState) => ({
  liquidityMiningStake,
  liquidityMiningLQTYReward
});

export const UnstakeAndClaim: React.FC = () => {
  const { dispatchEvent } = useFarmView();
  const { liquidityMiningStake, liquidityMiningLQTYReward } = useLiquitySelector(selector);
  const hasReward = !liquidityMiningLQTYReward.isZero;

  const {
    liquity: { send: liquity }
  } = useLiquity();

  const transactionState = useMyTransactionState(transactionId);

  const transactionAction = hasReward
  ? liquity.exitLiquidityMining.bind(liquity, "unipool1")
  : liquity.unstakeUniTokens.bind(liquity, "unipool1", liquidityMiningStake);

  useEffect(() => {
    if (transactionState.type === "confirmedOneShot") {
      dispatchEvent("UNSTAKE_AND_CLAIM_CONFIRMED");
    }
  }, [transactionState.type, dispatchEvent]);

  return (
    <Transaction
      id={transactionId}
      send={transactionAction}
      showFailure="asTooltip"
      tooltipPlacement="bottom"
    >
      <Button variant="outline" sx={{ mt: 3, width: "100%" }}>
        Unstake and claim reward
      </Button>
    </Transaction>
  );
};