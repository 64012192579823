import React from "react";
import { Link } from "./Link";
import { Box, Image } from "theme-ui";

type LiquityLogoProps = React.ComponentProps<typeof Box> & {
  height?: number | string;
};

export const LiquityLogo: React.FC<LiquityLogoProps> = ({ height, ...boxProps }) => (
  <Box sx={{ lineHeight: 0 }} {...boxProps}>
    <Link sx={{ fontSize: 1 }} to="/#">
      <Image src="./earn-logo.png" sx={{ height }} />
    </Link>
  </Box>
);
